export default {
    force: [
        { title: 'smoking', value: 1, gender: [0, 1] },
        { title: 'religion', value: 2, gender: [0, 1] },
        { title: 'university', value: 3, gender: [0, 1] },
    ],
    smoking: [
        { title: '상관없음', value: 0, gender: [0, 1] },
        { title: '비흡연', value: 1, gender: [0, 1] },
        { title: '흡연', value: 2, gender: [0, 1] },
    ],
    religion: [
        { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
        { title: '무교', value: 1, gender: [0, 1] },
        { title: '기독교', value: 2, gender: [0, 1] },
        { title: '천주교', value: 3, gender: [0, 1] },
        { title: '불교', value: 4, gender: [0, 1] },
        { title: '기타', value: 5, gender: [0, 1] },
    ],
    university: [
        {
            title: '전부 괜찮아요',
            value: 0,
            gender: [0, 1],
        },
        {
            title: '전문대학원(대학교)',
            value: 2,
            desc: '로스쿨, 의대(의전), 치대(치전), 한의대(한의전), 약대, 수의대',
            gender: [0, 1],
        },
        {
            title: '서연고카포',
            value: 3,
            desc: '서울대, 연세대, 고려대, 카이스트, 포항공대',
            gender: [0, 1],
        },
        {
            title: '서성한',
            value: 4,
            desc: '서강대, 성균관대, 한양대',
            gender: [0, 1],
        },
        {
            title: '중경외시',
            value: 5,
            desc: '중앙대, 경희대, 한국외대, 서울시립대',
            gender: [0, 1],
        },
        {
            title: '서울소재4년제',
            value: 6,
            desc: '건국대, 동국대, 홍익대 등 서울 소재 4년제',
            gender: [0, 1],
        },
        {
            title: '주요국립대학교',
            value: 8,
            desc: '부산대, 경북대, 전남대, 전북대, 충남대, 충북대,인천대',
            gender: [0, 1],
        },
        {
            title: '수도권4년제',
            value: 9,
            desc: '경기 소재 4년제',
            gender: [0, 1],
        },
        {
            title: '지방 4년제',
            value: 10,
            desc: '지방 4년제',
            gender: [0, 1],
        },
        {
            title: '전문대학(2,3년제)',
            value: 11,
            desc: '초대졸',
            gender: [0, 1],
        },
        {
            title: '고등학교 졸업',
            value: 12,
            desc: '고등학교 졸업',
            gender: [0, 1],
        },
        {
            title: '세계 100대 해외대학교',
            value: 1,
            desc: '아이비리그, 케임브릿지 등 QS Top 100대학교',
            gender: [0, 1],
        },
        {
            title: '해외대학교',
            value: 13,
            desc: '해외 소재 대학교',
            gender: [0, 1],
        },
        {
            title: '교대/교원대',
            value: 7,
            desc: '서울교대, 경인교대, 한국교원대 등',
            gender: [0, 1],
        },
        {
            title: '특수학교',
            value: 14,
            desc: '과학기술원(GIST, UNIST 등), 경찰대, 사관학교',
            gender: [0, 1],
        },
        {
            title: '예체능계',
            value: 15,
            desc: '서울예대, 한예종, 한국체대, 예체능계열 학과 등',
            gender: [0, 1],
        },
    ],
}
