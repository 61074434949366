<template>
    <div class="modal-edit-style-type items-start">
        <div class="header" @click="$emit('close')">
            <img :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" class="m-r-10" />
            <div class="key f-18 c-black" v-html="$translate(`${options.key.toUpperCase()}`)" />
        </div>
        <div class="body" ref="body" v-if="items.length">
            <div class="title" v-html="$translate(`MODAL_${options.key.toUpperCase()}_TYPE`)" />
            <div v-if="multiSelect" class="text-center f-12 c-grey-06">(중복 선택 가능)</div>
            <div class="types m-b-16" :class="{ 'grid-col': displayGrid }">
                <div
                    @click="select(item)"
                    class="item"
                    :class="{ selected: item.$$selected }"
                    :key="item.title"
                    v-for="item in items"
                >
                    <div class="item-title" v-html="item.title" />
                    <div v-if="item.desc" class="f-12" v-html="item.desc" />
                </div>
            </div>
            <div class="check-box">
                <div class="label" :class="{ inactive: items[0].$$selected }">
                    <div class="click" @click="clickCheckBox">
                        <img v-if="checkBox" :src="require('@/assets/images/select-active.png')" alt="" />
                        <img v-else :src="require(`@/assets/images/select-inactive.png`)" alt="" />
                    </div>
                    <div class="desc">
                        <span class="f-14 m-r-4 m-l-4">무조건 반영</span>
                        <img
                            @click="clickCheckboxInfo"
                            :src="require(`@/assets/images/icons/info${items[0].$$selected ? '-inactive' : ''}.png`)"
                            width="16px"
                            height="16px"
                        />
                    </div>
                </div>
                <div class="additional-info" v-show="checkInfo">
                    <ForceCheck />
                </div>
            </div>
        </div>
        <BottomButton :label="$translate('SAVE')" @click="submit" />
    </div>
</template>

<script>
import ForceCheck from '@/routes/my-page/components/ForceCheck'
import matchPreference from '@/assets/constants/match-preference'

export default {
    name: 'ModalEditStyleType',
    props: ['options'],
    data: () => ({
        items: [],
        checkInfo: false,
        checkBox: false,
    }),
    components: {
        ForceCheck,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        displayGrid() {
            return ['religion'].includes(this.options.key)
        },
        multiSelect() {
            return ['religion', 'university'].includes(this.options.key)
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        clickCheckBox() {
            // 상관없음 선택 시 선택 불가
            if (this.items[0].$$selected) return

            const checked = localStorage.getItem('click-force')
            if (!checked) {
                this.$modal.custom({
                    component: 'ModalForceCheck',
                    options: {
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    },
                })
                localStorage.setItem('click-force', true)
            }
            this.checkBox = !this.checkBox
        },
        clickCheckboxInfo() {
            const body = this.$refs.body
            this.checkInfo = !this.checkInfo
            setTimeout(() => body.scrollBy({ top: 99999, behavior: 'smooth' }), 200)
        },
        init() {
            const gender = this.me.gender === 0 ? 'male' : 'female'
            const types = matchPreference[this.options.key].filter(item => item.gender.includes(this.me.gender))
            const multiSelectIds = this.multiSelect
                ? ((this.me.style || {})[`${this.options.key}_type`] || []).map(item => item.value)
                : []

            this.items = (this.options.key === 'height' ? types[gender] : types).map(o => {
                if (this.multiSelect) {
                    this.$set(o, '$$selected', multiSelectIds.includes(o.value))
                } else {
                    this.$set(o, '$$selected', o.value === (this.me.style || {})[`${this.options.key}_type`])
                }
                return o
            })
            this.checkBox = this.me.style.force_type.some(item => item.title === this.options.key)
        },
        select(item) {
            // 상관없음 선택 시 무조건반영 해제
            if (item.value === 0) this.checkBox = false

            if (!this.multiSelect || item.value === 0) {
                this.items = this.items.map(o => ({
                    ...o,
                    $$selected: o.value === item.value,
                }))
            } else {
                const alreadySelectAll = this.items.some(item => item.value === 0 && item.$$selected)
                if (alreadySelectAll) {
                    this.items[0].$$selected = false
                }
                item.$$selected = !item.$$selected

                // 전체 선택 시 전부 괜찮아요로 수정
                const selectAllManually = this.items.filter(item => item.$$selected).length === this.items.length - 1
                if (selectAllManually) {
                    this.items = this.items.map(o => ({
                        ...o,
                        $$selected: o.value === 0,
                    }))
                    this.checkBox = false
                }
            }
        },
        submit() {
            this.$emit('close', { items: this.items, force: this.checkBox })
        },
    },
}
</script>
<style scoped lang="scss">
.modal-edit-style-type {
    overflow-y: hidden;
}
.body {
    padding: 30px 0;
    height: calc(100vh - 56px - 70px);
    overflow-y: auto;
}
.header {
    position: sticky;
    top: 0;
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 16px;
    padding: 16px;
}
.title {
    font-size: 18px;
    text-align: center;
    color: black;
}
.grid-col {
    .item {
        margin: 0 !important;
    }
}
.item {
    text-align: center;
}
.check-box {
    .label {
        display: flex;
        align-items: center;
        padding: 0 16px;

        img {
            margin: 0;
            width: 20px;
            height: 20px;
        }
        .desc {
            display: flex;
            align-items: center;
            color: black;
        }

        &.inactive {
            .desc {
                color: $grey-04;
            }
        }
    }

    .additional-info {
        padding: 8px 16px;
        margin: 16px;
        border: 1px solid $grey-06;
        border-radius: 8px;
        font-size: 12px;
    }
}
</style>
